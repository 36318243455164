<template>
  <div class="wrapper">
    <el-dialog :close-on-click-modal="false" :modal-append-to-body="false"  
        class="label_dialog lineheight40"
        title="筛选标签组"
        width="30%"
        center
        :visible.sync="dialogVisible"
        
      >
        <div class="mb20" v-for="(item, index) in tags_lists" :key="index">
          <div class="cA2">{{ item.lableName }}</div>
          <div class="pl30 d-flex flex-wrap-y">
            <div
              class="pl20 pr20 ml20 mb20 br20 hover_pointer"
                :class="v.isSelect ? '  trans2' : 'bbD7E0 trans2'"
                :style="{
                  background: v.isSelect ? v.lableColor : '',
                  color: !v.isSelect ? '#737F80' : v.lableColor !== '#F5F5F6' ? '#ffffff' : '#737F80' ,
                  border: v.isSelect ?  `1px solid `+ v.lableColor : ''
                }"
              v-for="(v, k) in item.list"
              :key="k"
              @click="chooseTag(v, index, k)"
            >
              {{ v.lableName }}
            </div>
          </div>
        </div>
        <div class="flex-c-c">
          <el-button @click="resetTap()">重置</el-button>
          <el-button
            class="save"
            type="primary"
            @click="onConfirmTap()"
            >确定</el-button
          >
        </div>
      </el-dialog>
  </div>
</template>

<script>

import { fetchClient } from "@/api/client";
export default {
  props:{
    visible:{ //显隐
      type:Boolean,
      default:false
    },
  },
  data() {
    return {
      tags_lists:[],
      tab:[],
    };
  },
  computed: {
    dialogVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('updateVisible', val)
      }
    }
  },
  created() {
    this.getClientLabel()
  },
  methods:{
    getClientLabel() {
      fetchClient({ ignore: true })
      .then((res) => {
        let data = res.data;
        if (data) {
          this.tags_lists = [];
          let datas = [];
          data.forEach(function (i1, k1) {
            datas.push({
              lableName: i1.lableName,
              list: [],
              id: i1.lableId,
              lableId: i1.lableId,
            });
            i1.lableResponses &&
              i1.lableResponses.forEach(function (i2, k2) {
                datas[k1].list.push({
                  lableName: i2.lableName,
                  lableColor: i2.lableColor,
                  id: i2.lableId,
                  lableId: i2.lableId,
                  isSelect:false
                });
              });
          });
          this.tags_lists = datas;
          this.tags_lists_old = datas
        }
      })
      .catch((err) => {
        //-console.log(err);
      });
    },
    chooseTag(item, index , k ) {
      this.tags_lists[index].list.forEach((val,index)=>{
        if(index !== k){
          val.isSelect = false 
        }
      })
      this.$set(this.tags_lists[index].list[k],'isSelect',!this.tags_lists[index].list[k].isSelect)
      this.tab[index] = this.tags_lists[index].list[k].isSelect ? this.tags_lists[index].list[k] : ''
    },
    resetTap(){
      for(var i in this.tags_lists){
        this.tags_lists[i].list.forEach((val,index)=>{
          val.isSelect = false 
        })
      }
      this.tab = [{},{},{}]
      this.onConfirmTab = []
    },
    
    onConfirmTap(){
      let arr = []
      if(this.tab){
        this.tab.forEach(val=>{
          if(val.lableId){
            arr.push(val.lableId)
          }
        })
      }
      //-console.log(arr)
      this.$emit('onConfirmTap',arr)
    },
  }
};
</script>

<style lang="scss" scoped>

</style>
